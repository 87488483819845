import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';

const API_URL = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=200&page=1&sparkline=false&price_change_percentage=1h%2C24h%2C7d%2C30d%2C1y';

const OverlayMenu = ({ timeframe, setTimeframe, darkMode, toggleDarkMode, showOnlyFavorites, setShowOnlyFavorites }) => (
  <div className="flex justify-around items-center w-full p-2 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
    <select
      value={timeframe}
      onChange={(e) => setTimeframe(e.target.value)}
      className="p-2 rounded-md text-sm bg-gray-100 dark:bg-gray-700 text-black dark:text-white"
    >
      {['1h', '24h', '7d', '30d', '1y'].map(option => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
    <button 
      onClick={toggleDarkMode} 
      className="px-3 py-2 rounded-full text-sm bg-gray-100 dark:bg-gray-700 text-black dark:text-white"
    >
      {darkMode ? '☀️ Light' : '🌙 Dark'}
    </button>
    <button
      onClick={() => setShowOnlyFavorites(!showOnlyFavorites)}
      className={`px-4 py-2 rounded-md text-sm ${showOnlyFavorites ? 'bg-blue-500 text-white' : 'bg-gray-100 dark:bg-gray-700 text-black dark:text-white'}`}
    >
      {showOnlyFavorites ? 'All' : 'Favorites'}
    </button>
  </div>
);

const CryptoDashboard = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const [timeframe, setTimeframe] = useState('24h');
  const [darkMode, setDarkMode] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [favorites, setFavorites] = useState([]);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const overlayTimerRef = useRef(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(API_URL);
      const data = await response.json();
      setCryptoData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  useEffect(() => {
    fetchData();
    setDarkMode(new Date().getHours() >= 22 || new Date().getHours() < 7);
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, [fetchData]);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
  }, [darkMode]);

  useEffect(() => {
    if (showOverlay) {
      overlayTimerRef.current = setTimeout(() => setShowOverlay(false), 5000);
    }
    return () => clearTimeout(overlayTimerRef.current);
  }, [showOverlay]);

  const toggleDarkMode = useCallback(() => setDarkMode(prev => !prev), []);

  const getPercentageChange = useCallback((coin) => {
    const key = `price_change_percentage_${timeframe}_in_currency`;
    return coin[key];
  }, [timeframe]);

  const getChangeColor = useCallback((change) => {
    if (change > 0) return 'bg-green-500 text-white';
    if (change < 0) return 'bg-red-500 text-white';
    return 'bg-gray-500 text-white';
  }, []);

  const sortData = useCallback((key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'ascending' ? 'descending' : 'ascending'
    }));
  }, []);

  const toggleFavorite = useCallback((coinId) => {
    setFavorites(prev => prev.includes(coinId) ? prev.filter(id => id !== coinId) : [...prev, coinId]);
  }, []);

  const handleCoinInteraction = useCallback((coinId, event) => {
    if (event.type === 'contextmenu' || (event.type === 'touchstart' && event.touches.length === 1)) {
      event.preventDefault();
      toggleFavorite(coinId);
    }
  }, [toggleFavorite]);

  const sortedAndFilteredData = useMemo(() => {
    let sortableData = showOnlyFavorites ? cryptoData.filter(coin => favorites.includes(coin.id)) : cryptoData;
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (sortConfig.key === 'change') {
          return (getPercentageChange(a) - getPercentageChange(b)) * (sortConfig.direction === 'ascending' ? 1 : -1);
        }
        return (a[sortConfig.key] > b[sortConfig.key] ? 1 : -1) * (sortConfig.direction === 'ascending' ? 1 : -1);
      });
    }
    return sortableData;
  }, [cryptoData, sortConfig, showOnlyFavorites, favorites, getPercentageChange]);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 text-black dark:text-white relative">
      <div className="max-w-7xl mx-auto p-4">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl sm:text-3xl font-bold">Crypto Dashboard</h1>
          <button 
            className="sm:hidden bg-blue-500 text-white px-3 py-1 rounded"
            onClick={() => setShowOverlay(prev => !prev)}
          >
            ☰
          </button>
          <div className="hidden sm:flex items-center space-x-4">
            <select
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value)}
              className="p-2 rounded-md text-sm sm:text-base bg-white dark:bg-gray-700 text-black dark:text-white"
            >
              {['1h', '24h', '7d', '30d', '1y'].map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
            <button 
              onClick={toggleDarkMode} 
              className="px-3 py-2 rounded-full text-sm sm:text-base bg-white dark:bg-gray-700 text-black dark:text-white"
            >
              {darkMode ? '☀️ Light' : '🌙 Dark'}
            </button>
            <button
              onClick={() => setShowOnlyFavorites(prev => !prev)}
              className={`px-4 py-2 rounded-md ${showOnlyFavorites ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-700'}`}
            >
              {showOnlyFavorites ? 'Show All' : 'Show Favorites'}
            </button>
          </div>
        </div>
        <div className="overflow-x-auto bg-white dark:bg-gray-800 rounded-lg shadow">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-200 dark:bg-gray-700">
                {['Name', 'Current Price', 'Change'].map(header => (
                  <th 
                    key={header}
                    className="p-3 text-left text-xs sm:text-sm font-medium cursor-pointer" 
                    onClick={() => sortData(header.toLowerCase().replace(' ', '_'))}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredData.map((coin) => {
                const percentChange = getPercentageChange(coin);
                const isFavorite = favorites.includes(coin.id);
                return (
                  <tr 
                    key={coin.id} 
                    className={`border-b dark:border-gray-700 ${isFavorite ? 'bg-yellow-100 dark:bg-yellow-900' : ''}`}
                    onClick={() => toggleFavorite(coin.id)}
                    onContextMenu={(e) => handleCoinInteraction(coin.id, e)}
                    onTouchStart={(e) => handleCoinInteraction(coin.id, e)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td className="p-3 text-xs sm:text-sm flex items-center">
                      {isFavorite && <span className="mr-2">⭐</span>}
                      {coin.name}
                    </td>
                    <td className="p-3 text-xs sm:text-sm">${coin.current_price.toLocaleString()}</td>
                    <td className="p-3 text-xs sm:text-sm">
                      <span className={`px-2 py-1 rounded ${getChangeColor(percentChange)}`}>
                        {percentChange?.toFixed(2)}%
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div 
        className={`fixed top-0 left-0 right-0 transform transition-transform duration-300 ease-in-out ${showOverlay ? 'translate-y-0' : '-translate-y-full'}`}
      >
        <OverlayMenu 
          timeframe={timeframe}
          setTimeframe={setTimeframe}
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          showOnlyFavorites={showOnlyFavorites}
          setShowOnlyFavorites={setShowOnlyFavorites}
        />
      </div>
    </div>
  );
};

export default CryptoDashboard;